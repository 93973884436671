import React from 'react';

import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Alphanorth 404" />
    <h1>404</h1>
    <p>A página que você está acessando não existe.</p>
  </Layout>
);

export default NotFoundPage;
